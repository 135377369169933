<template>
  <el-card style="min-width: 245px" class="left">
    <div v-show="showShaiBox" class="shaiBox">
      <div class="shai_title clearfix cp">
        <span
          @click="showOpt = !showOpt"
          class="fl"
          :class="{
            'el-icon-arrow-up': showOpt,
            'el-icon-arrow-down': !showOpt,
          }"
          >已筛选({{his_cities.length}})</span
        ><span @click="closeBox" class="el-icon-close fr f20"></span>
      </div>
      <div v-show="showOpt">
        <div v-for="(item,index) in shai_list" :key="index"  class="shaiOpt">
          <span>筛选<span style="color: #333">国家：</span>{{item}}</span
          ><span @click="handlerDleOpt(index)" class="el-icon-close f20 cp"></span>
        </div>
      </div>
    </div>
    <div class="header">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >{{ $t("tab.allPantent") }}</el-checkbox
      >
      <span class="myfr">{{ $t("tab.data_range") }}</span>
    </div>
    <div style="margin: 15px 0"></div>
    <el-checkbox-group
      v-model="checkedCities"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox v-for="city in cities" :label="city.title" :key="city.title">
        <img class="flag_icon" :src="city.icon" alt="" />
        <span
          >{{ city.title }}
          <span>
            {{ city.value ? "(" + city.value + ")" : "" }}
          </span>
        </span>
      </el-checkbox>
    </el-checkbox-group>
    <el-button
      style="margin-top: 20px"
      v-if="$route.name == 'List'"
      @click="screen"
      >筛选</el-button
    >
  </el-card>
</template>

<script>
export default {
  data() {
    const allOption = [
      this.$t("tab.flag.CN"),
      this.$t("tab.flag.US"),
      this.$t("tab.flag.EU"),
      this.$t("tab.flag.JP"),
      this.$t("tab.flag.KR"),
      this.$t("tab.flag.WO"),
      this.$t("tab.flag.DE"),
      this.$t("tab.flag.FR"),
      this.$t("tab.flag.TW"),
      this.$t("tab.flag.HK"),
      this.$t("tab.flag.RU"),
    ];
    const checkAll = this.$route.name == "List" ? false : true;
    const checkedCities = this.$route.name == "List" ? [] : allOption;
    return {
      isIndeterminate: false,
      checkAll,
      checkedCities,
      allOption,
      cities: [
        {
          title: this.$t("tab.flag.CN"),
          icon: require("@/assets/img/flag/CN.png"),
        },
        {
          title: this.$t("tab.flag.US"),
          icon: require("@/assets/img/flag/US.png"),
        },
        {
          title: this.$t("tab.flag.EU"),
          icon: require("@/assets/img/flag/EU.png"),
        },
        {
          title: this.$t("tab.flag.JP"),
          icon: require("@/assets/img/flag/JP.png"),
        },
        {
          title: this.$t("tab.flag.KR"),
          icon: require("@/assets/img/flag/KR.png"),
        },
        {
          title: this.$t("tab.flag.WO"),
          icon: require("@/assets/img/flag/WO.png"),
        },
        {
          title: this.$t("tab.flag.RU"),
          icon: require("@/assets/img/flag/RU.png"),
        },
        {
          title: this.$t("tab.flag.DE"),
          icon: require("@/assets/img/flag/DE.png"),
        },
        {
          title: this.$t("tab.flag.FR"),
          icon: require("@/assets/img/flag/FR.png"),
        },
        {
          title: this.$t("tab.flag.TW"),
          icon: require("@/assets/img/flag/TW.png"),
        },
        {
          title: this.$t("tab.flag.HK"),
          icon: require("@/assets/img/flag/HK.png"),
        },
      ],
      c_nums: [],
      showOpt: true,
      showShaiBox: false,
      his_cities: [],
      shai_list: [],
    };
  },
  mounted() {
    console.log(this.checkedCities);
  },
  computed: {
    getShaiCities(){
        const arr = [];
        this.cities.forEach(item => {
          arr.push(item.title)
        });
        return arr.toString()
    }
  },
  props: {
    citie_js_nums: {
      //各国检索结果数量
      typeof: Array,
      default: () => [], //工厂函数返回
    },
    dbs: {
      //选中国家集
      typeof: Array,
      default: () => [],
    },
  },
  watch: {
    //父组件给组件传值，子组件依靠此数据初始化，有必要进行监听。
    citie_js_nums: {
      handler(val) {
        this.handlerGetNums(val);
      },
      immediate: true,
    },
    dbs: {
      handler(val) {
        this.handlerGetDbs(val);
      },
      immediate: true,
    },
  },
  methods: {
    // 删除某个检索选项
    handlerDleOpt(index){
      if(this.shai_list.length > 1){
        this.$delete(this.shai_list,index);
        this.$delete(this.his_cities,index);
        console.log(this.his_cities);
        this.cities = this.his_cities[this.his_cities.length-1];
        const arr = [];
        this.cities.forEach(item => {
          arr.push(item.title);
        })
        this.$emit('screen',arr);
      }else {
        this.closeBox();
      }
    },
    // 退出筛选
    closeBox(){
      // this.cities = this.his_cities;
      // this.showShaiBox = false;
      // 奇葩需求，直接刷新
        location.reload();
    },
    // 筛选
    screen() {
      if (this.checkedCities.length > 0) {
        this.showShaiBox = true;
        console.log(this.checkedCities);
        const arr = [];
        this.checkedCities.forEach((val) => {
          this.cities.forEach((item) => {
            if (val == item.title) {
              //  this.checkedCities.push(item.title);
              arr.push(item);
            }
          });
        });
        this.cities = arr;
        this.his_cities.push(this.cities);
        console.log(this.his_cities);
        this.shai_list.push(this.getShaiCities);
        this.$emit("screen", this.checkedCities);
        this.checkedCities = [];
        this.isIndeterminate = false;
        this.checkAll = false;
      } else {
        this.$message.warning("请选择数据范围");
      }
    },
    // 获取初始选项
    handlerGetDbs(dbs) {
      const arr = [];
      dbs.forEach((val) => {
        this.cities.forEach((item) => {
          if (val == item.title.substr(-3, 2)) {
            //  this.checkedCities.push(item.title);
            arr.push(item);
          }
        });
      });
      if (dbs.length > 0) this.cities = arr;
      console.log(this.checkedCities);
    },
    // 获取各国检索数量   监听异步数据结合vue响应式修改实现视图更新
    handlerGetNums(c_nums) {
      c_nums.forEach((val) => {
        this.cities.forEach((item, index) => {
          if (val.key.toUpperCase() == item.title.substr(-3, 2)) {
            this.$set(this.cities[index], "value", val.value); //
          }
        });
      });
    },
    // 全选
    handleCheckAllChange(val) {
      const allOption = [];
      this.cities.forEach(item => {
        allOption.push(item.title);
      })
      this.checkedCities = val ? allOption : [];
      this.isIndeterminate = false;
      this.$emit("getDbs", this.checkedCities);
    },
    // 单选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
      this.$emit("getDbs", this.checkedCities);
    },
  },
};
</script>

<style lang="scss" scoped>
.left {
  width: 16%;
  // height: calc(100vh - 262px);
  padding-top: 12px;
  .shaiBox {
    width: 90%;
    line-height: 26px;
    border: 1px solid #12bdd4;
    color: #12bdd4;
    margin: 0 auto 15px;
    padding: 10px;
    .shai_title {
      height: 30px;
      padding: 10px;
    }
    .shaiOpt {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #daf3f7;
      padding: 10px;
      margin-bottom: 5px;
    }
  }
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    .el-checkbox {
      display: flex;
      align-items: center;
    }
    .flag_icon {
      width: 28px;
      height: 18px;
      margin-right: 4px;
    }
  }
}
</style>
<style>
.left .el-checkbox-group .el-checkbox__label {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
}
.el-checkbox .el-checkbox__label {
  font-size: 16px;
}

.myfr {
  font-size: 14px !important;
  float: right;
}
</style>